@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Playwrite+CU:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Pacifico&family=Playwrite+CU:wght@100..400&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@550&display=swap');


@keyframes reverseGradient {
    0% {
      background-position: left center;
    }
    100% {
      background-position: right center;
    }
  }