@import url('https://fonts.googleapis.com/css?family=Cardo|Pathway+Gothic+One');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Playwrite+CU:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Pacifico&family=Playwrite+CU:wght@100..400&display=swap');


.timeline {
    display: flex;
    /* margin: 0 auto; */
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    position: relative;

}

.timeline:before {
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    content: "";
    background: rgba(255, 255, 255, 0.07);
 
}


@media only screen and (max-width: 767px) {
    .timeline:before {
      left: 40px;
    }
  }
  

.timeline_title{
    font-family: 'Pacifico', cursive;
    font-size: 4rem;
    font-weight: normal;
    margin: -10px 0 0 0;
    transition: .4s;
    padding: 0 0px;
    box-sizing: border-box;
    text-shadow: 2px 2px  #000;
    color: #501a1f;
}

.timeline_title:hover{
    color: #501a1f;
    text-shadow: .5px .5px  #000;
}


.timeline_description{
    font-family: 'Cardo', serif;
    font-size: 1.3rem;
    font-weight: normal;
    margin: 0;
    line-height: 25px;
    box-sizing: border-box;
    color: rgba(193, 216, 233, 0.809);
    display: flex;
    gap : 40px;
    list-style-type: none;
    text-align: center;
    justify-self: center;
}

.timeline_description li {
    background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/check.svg') no-repeat left center; /* Set the image as background */
    background-size: 20px 20px; /* Adjust the size of the image */
    padding-left: 30px; /* Add padding to make space for the image */
}

.timeline_description li:hover{
    color: #0c0a22;
    text-shadow: .5px .5px  #000;
    margin-bottom: 0;

}

.timeline_item {
    padding: 40px 0;
    opacity: .3;
    filter: blur(2px);
    transition: .5s;
    box-sizing: border-box;
    width: calc(50% - 40px); 
    height: 60vh;
    display: flex;
    position: relative;
    transform: translateY(-80px);
}

.timeline_item:hover {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}


.timeline_item:before {
    content: attr(data-text);
    letter-spacing: 3px;
    width: 100%;
    position: absolute;
    color: rgb(255, 255, 255, 0.5);
    font-size: 3.6rem;
    font-family: 'Pathway Gothic One', sans-serif;
    border-left: 2px solid rgba(255, 255, 255, 0.5);
    top: 70%;
    margin-top: -5px;
    padding-left: 15px;
    opacity: 0;
    right: calc(-100% - 56px);
}

.timeline_item:nth-child(odd) {
    align-self: flex-start;
    left: 0%;
    padding-left: 90px;
   
}

.timeline_item:nth-child(even) {
    align-self: flex-end;
    right: 0;
}

.timeline_item:nth-child(even):before{
    right: auto;
    text-align: right;
    left: calc(-100% - 56px);
    padding-left: 0;
    border-left: none;
    border-right: 2px solid rgba(255, 255, 255, .5);
    padding-right: 15px;
}

.timeline_item--active {
    opacity: 1;
    filter: blur(0px);
    transform: translateY(0px);
}

.timeline_item--active:before {
    top: 50%;
    opacity: 1;
    transition: .3s all .2s;
}

.timeline_item--active .timeline_title {
    margin: -50px 0 20px 0;
}

.timeline_img{
    max-width: 100%;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .4);
    border-radius: 20px;
}

.timeline_container {
    width: 100%;
    /* border-radius: 20%; */
    position: relative;
    padding: 20px;
    transition: .3s ease 0s;
    background-attachment: fixed;
    background-size: cover;
    height: 210vh;
    /* left: 10vw; */
    overflow: hidden;
    
}

.timeline_container:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0, 0, 0, 0.8); */
    border-radius: 20%;
    content: "";
  }

  @media only screen and (max-width: 767px) {
    .timeline_item {
      align-self: baseline !important;
      width: 100%;
      padding: 0 30px 150px 80px;
    }
  
    .timeline_item:before {
      left: 10px !important;
      padding: 0 !important;
      top: 50px;
      text-align: center !important;
      width: 60px;
      border: none !important;
    }
  
    .timeline_item:last-child {
      padding-bottom: 40px;
    }
  }
  
  