footer{
    text-align: center;
    color : #410b0b;

}

footer::before{
    content: "";
    display: block;
    width: 70vw;
    height: 5px;
    border-bottom: 2px solid #410b0b;
    margin-bottom: 10px;

}

.coloured-text{
    color: #1f6e90;
    text-shadow: .5px .5px  #a7a4a4;
}

/* .coloured_text{
    color: #f2e2d2;;
    text-shadow: .5px .5px  #000;
    font-weight: bold;
} */

/*Media Queries For Responsive Design*/