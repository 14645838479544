
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

.link{
    display: inline-block;
    padding : 15px 15px;
    position: relative;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0px 30px;
    cursor: pointer;

    &:before, &:after{
        content:" ";
        height:10px;
        width:10px;
        position:absolute;
        transition: all .35s ease;
        opacity: 0;
    }
    &:before{
        /* content: ""; */
        right: 0;
        top: 0;
        border-top: 5px solid #1c1b1b;
        border-right: 5px solid #b5b6b7;
        transform: translate(-100%, 50%);
    }

    &:after{
        /* content: ""; */
        left: 0;
        bottom: 0;
        border-bottom: 5px solid #1c1b1b;
        border-left: 5px solid #b5b6b7;
        transform: translate(100%, -50%);
    }

    &:hover:before, &:hover:after{
        transform: translate(0,0);
        opacity: 1;
    }

    /* &:hover{
        color: #501a1f;
    } */
}

.eshi-logo{
    margin: auto;
}

.app-header{
    background-color: #FBF9F3; 
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #1c1b1b;
    position: fixed; 
    top: 0; 
    left: 0; 
    right: 0; 
    z-index: 1000;
    padding: 10px 20px;
    transition: all 0.3s ease;
}

.nav{
    margin-right: 10px;
}

.nav ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: auto;
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
    .app-header {
        padding: 8px 16px;
    }

    .link {
        padding: 8px 12px;
        margin: 0px 8px;
    }
}

@media screen and (max-width: 768px) {
    .app-header {
        display: none;
    }

    .link {
        padding: 6px 10px;
        margin: 0px 6px;
    }

    .nav ul {
        justify-content: space-around;
    }
}

@media screen and (max-width: 480px) {
   .app-header {
        display: none;
    }

    .link {
        padding: 4px 8px;
        margin: 0px 4px;
    }

    .nav ul {
        justify-content: space-between;
    }

    .eshi-logo{
        text-align: center;
    }
}