@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Playwrite+CU:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Pacifico&family=Playwrite+CU:wght@100..400&display=swap');

/* Base styles */
#skills-section {
    width: 100%;
    height: auto;
    color: #b5b6b7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.technical-skills {
    width: 100%;
    margin: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.lang-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    width: 100%;
    margin-left: 2vw;
    /* margin: auto; */
}

.lang {
    color: #000000;
    font-size: 1.5rem;
    background-color: #fff;
    border-radius: 40px;
    padding: 10px;
}

i{
    color: #041f4f;
}

.lang .info{
    color:#000000
}


.lang:hover {
    color: #0c0a22;
}

.lang-list .lang {
    margin: 0px;
}

.lang-list .lang:first-child {
    margin-top: 0;
}

.lang-list .lang:last-child {
    margin-bottom: 0;
}

.percentage, .info {
    font-size: 1.6rem;
    font-weight: 500;
    font-family: "Pathway Gothic One";
    animation: showText 0.5s 1.5s linear forwards;
    opacity: 1;
}

.coloured_text {
    color: #410b0b;
}

.lang-list .lang .progress_bar {
    position: relative;
    border-radius: 15px;
    width: 70%;
    height: 10px;
    background-color: #000000;
    animation: animate 1s cubic-bezier(1,0,0.5,1) forwards;
    transform: scaleX(0);
    transform-origin: left;
}

.lang-list .lang .progress_bar:hover {
    animation: animate 1s cubic-bezier(1,0,0.5,1) forwards;
    transform: scaleX(0);
    transform-origin: left;
}

@keyframes animate {
    100% {
        transform: scaleX(1);
    }
}

.lang-list .lang .progress_bar span {
    position: absolute;
    border-radius: 15px;
    height: 100%;
    background-color: #410b0b;
    transform-origin: left;
    animation: animate 1s 1s cubic-bezier(1,0,0.5,1) forwards;
    transform: scaleX(0);
    transform-origin: left;
}

.progress_bar.html span { width: 90%; }
.progress_bar.css span { width: 80%; }
.progress_bar.js span { width: 75%; }
.progress_bar.shell span { width: 64%; }
.progress_bar.git span { width: 97%; }
.progress_bar.objc span { width: 78%; }
.progress_bar.python span { width: 85%; }
.progress_bar.cpp span { width: 63%; }
.progress_bar.c span { width: 72%; }
.progress_bar.java span { width: 95%; }
.progress_bar.sql span { width: 77%; }
.progress_bar.kotlin span { width: 60%; }
.progress_bar.r span { width: 30%; }
.progress_bar.ts span { width: 45%; }
.progress_bar.go span { width: 40%; }

.progress_bar span::after {
    position: absolute;
    padding: 1px 5px;
    background-color: #0b4141;
    color: white;
    font-size: 0.8rem;
    border-radius: 3px;
    top: -35px;
    right: -20px;
    animation: showText 0.5s 1.5s linear forwards;
    opacity: 0;
}

.progress_bar.html span::after { content: "90%"; }
.progress_bar.css span::after { content: "80%"; }
.progress_bar.js span::after { content: "75%"; }
.progress_bar.shell span::after { content: "64%"; }
.progress_bar.git span::after { content: "97%"; }
.progress_bar.objc span::after { content: "78%"; }
.progress_bar.python span::after { content: "85%"; }
.progress_bar.cpp span::after { content: "63%"; }
.progress_bar.c span::after { content: "72%"; }
.progress_bar.java span::after { content: "95%"; }
.progress_bar.sql span::after { content: "77%"; }
.progress_bar.kotlin span::after { content: "60%"; }
.progress_bar.r span::after { content: "30%"; }
.progress_bar.ts span::after { content: "45%"; }
.progress_bar.go span::after { content: "40%"; }

.progress_bar span::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-bottom-width: 0px;
    border-right-width: 0px;
    border-top-color: #0b4141;
    top: -10px;
    right: 0;
    animation: showText 0.5s 1.5s linear forwards;
    opacity: 0;
}

@keyframes showText {
    100% {
        opacity: 1;
    }
}

.professional-skills {
    width: 70%;
    margin: 30px;
}

.professional-skills-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.professional-skills-list .info{
    color:#000000;
    font-size: 1.3rem;
}

.skill {
    color: #000000;
    font-size: 2rem;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    /* margin: auto; */
    background-color: #fff;
    border-radius: 50px;
    padding: 20px;
    margin: 20px;
}

.skill-info {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: "Pathway Gothic One";
    animation: showText 0.5s 1.5s linear forwards;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    align-content: left;
}

.skill-info .info {
    margin-left: 10px;
}

.skill-info i {
    color: #041f4f;
    text-shadow: 1px 1px #000;
    font-size: 1.7rem;
}

.skill svg {
    /* position: absolute; */
    transform: rotate(-90deg);
    display: block; /* Ensure the SVG is treated as a block element */
    margin: auto; /* Center the SVG */
}

.skill .progress-bar {
    stroke-width: 10;
    stroke: black;
    fill: transparent;
    stroke-dasharray: 188.50;
    stroke-dashoffset: 188.50;
    stroke-linecap: round;
    animation: animate-radial-bar 2s 2s linear forwards;
    margin: auto; /* Center the progress bar */
    display: block; /* Ensure the progress bar is treated as a block element */
    
}
.info{
    color:#0c0a22;
  
}

.tools-box .info{
    font-size: 1.2rem;
}

.devopps, .ide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.devopps .info, .ide .info, .framework .info, .platform .info  {
    display: flex;
    align-items: center;
    margin : 3px;
    font-size: 1.1rem;
}

.devopps .info i, .ide .info i, .framework .info i, .platform .info i {
    width: 36px;
    height: 36px;
}

.devopps .info span, .ide .info span {
    display: flex;
    align-items: center;
    
}

.devopps svg, .ide svg {
    margin: 0 10px; /* Adjust the margin as needed */
}



@keyframes animate-radial-bar {
    100% {
        stroke-dashoffset: 0;
    }
}

.progress-path {
    stroke-width: 5;
    stroke: #410b0b;
    fill: transparent;
    stroke-dasharray: 188.50;
    stroke-dashoffset: 188.50;
    stroke-linecap: round;
}

.path-1 {
    animation: animate-progress-path-1 2s 2s linear forwards;
}

.path-2 {
    animation: animate-progress-path-2 2s 2s linear forwards;
}

.path-3 {
    animation: animate-progress-path-3 2s 2s linear forwards;
}

.path-4 {
    animation: animate-progress-path-4 2s 2s linear forwards;
}

.path-5 {
    animation: animate-progress-path-5 2s 2s linear forwards;
}

@keyframes animate-progress-path-2 {
    100% {
        stroke-dashoffset: 47.15;
    }
}

@keyframes animate-progress-path-1 {
    100% {
        stroke-dashoffset: 30.75;
    }
}

@keyframes animate-progress-path-3 {
    100% {
        stroke-dashoffset: 15.675;
    }
}

@keyframes animate-progress-path-4 {
    100% {
        stroke-dashoffset: 37.85;
    }
}

@keyframes animate-progress-path-5 {
    100% {
        stroke-dashoffset: 42.25;
    }
}

.tools-box i{
    color:#501a1f;
    font-size: 2rem;
    margin: 10px;
    animation: showText 0.5s 1.5s linear forwards;
    opacity: 1;

}



/* Styles for medium screens (tablets, smaller laptops) */
@media (max-width: 1024px) {

    .heading {
        padding: 1rem 0;
    }

    #skills-section {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .technical-skills {
        width: 90%;
        margin-bottom: 2rem;
       
    }
    .professional-skills{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .professional-skills-list{
        font-size: 1.2rem;
    }

    .lang-list {
        width: 100%;
        margin-left: 0;
        grid-template-columns: 1fr 1fr;
        padding-left: 5vw;
        padding-right: 5vw;
    }

    .lang {
        font-size: 1.2rem;
    }

    .progress_bar {
        width: 60%;
    }

    .skill{
        margin: 20px;
    }

    .skill-info .info {
        font-size: 1.5rem;
    }

    .skill-info i {
        font-size: 1.5rem;
    }
    .percentage {
        font-size: 1.3rem;
    }

    

}

/* Styles for small screens (mobile devices) */
@media (max-width: 600px) {
    .heading {
        padding: 1rem 0;
    }

    .technical-skills, .professional-skills, .frameworks {
        width: 100%;
        margin-bottom: 1rem;
    }

    .lang-list {
        margin-left: 0;
        grid-template-columns: 1fr 1fr;
    }

    .professional-skills-list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        font-size: 1.2rem;
    }

    .lang, .skill {
        width: 100%;
        margin-bottom: 1rem;
        font-size: 1rem;
    }

    .lang {
        font-size: 1rem;
    }

    .progress_bar {
        width: 30%;
    }

    .progress_bar {
        width: 30%;
        font-size: 1rem;
    }

    .percentage {
        font-size: 1rem;
    }

    .skill{
        margin: 10px;
    }
    
    .skill-info .info {
        font-size: 1.3rem;
    }

    .percentage {
        font-size: 1.2rem;
    }

    .skill-info i {
        font-size: 1.4rem;
    }
}