@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@550&display=swap');

.projects-container {
    /* background-color: #ffff; */
    height: auto;
}

.slick-slide {
    display: flex;
    justify-content: center;
}

.slick-center .slick-slide {
    transform: scale(1.1);
    transition: transform 0.3s ease;
}

.flip-card {
    perspective: 1000px;
    width: 60vw;
    height: 70vh;

}

.background-video {
    position: fixed;
    z-index: -1;
    object-fit: fill;
    border: 2px solid #1c1b1b;
    height: 70vh;
    width: 90%;
    border-radius: 100px;
    box-shadow: 2px 2px 2px 2px #1c1b1b;
}

.flip-card-inner {
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d;
    position: relative;
}

.flip-card-front,
.flip-card-back {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* Safari support */
    position: absolute;
    /* background-color: #410b0b; */
    border-radius: 100px;
    height: 100%;
    width: 90%;
    
    
}

.flip-card-back {
    transform: rotateY(180deg);
}


@media (max-width: 1024px) {
    /* .projects-container {
        padding: 50px;
    } */
    .background-video {
        position: fixed;
        z-index: -1;
        object-fit: fill;
        border: 2px solid #1c1b1b;
        height: 50vh;
        width: 90%;
        object-fit: fill;
        border-radius: 100px;
    } 
    
    .flip-card {
        perspective: 1000px;
        width: 80vw;
        height: 50vh;
    
    }

    .flip-card-back {
    
        width: auto;
    
        
    }

  
}


@media (max-width: 768px) {
    /* .projects-container {
        padding: 50px;
    } */
    .background-video {
        position: fixed;
        z-index: -1;
        object-fit: fill;
        border: 2px solid #1c1b1b;
        height: 40vh;
        width: 90%;
        object-fit: fill;
        border-radius: 100px;
    } 
    
    .flip-card {
        perspective: 1000px;
        width: 90%;
        height: 40vh;
    
    }
  


  
}

@media (max-width: 480px) {
    /* .projects-container {
        padding: 5px;
    } */

    .background-video {
        position: fixed;
        z-index: -1;
        object-fit: fill;
        border: 2px solid #1c1b1b;
        height: 40vh;
        width: 100%;
        object-fit: fill;
        border-radius: 100px;
    }

    .flip-card {
        perspective: 1000px;
        width: 90%;
        height: 50vh;
    
    }

     
}

/* @media only screen 
    and (width: 390px) 
    and (height: 844px) 
    and (-webkit-device-pixel-ratio: 3) { 

        .background-video {
            position: fixed;
            z-index: -1;
            object-fit: fill;
            border: 2px solid #1c1b1b;
            height: 40vh;
            width: 100%;
            object-fit: fill;
            border-radius: 100px;
        }
    
        .flip-card {
            perspective: 1000px;
            width: 90%;
            height: 50vh;
        
        }
    } */


@supports (-webkit-backface-visibility: hidden) {
    .flip-card-front,
    .flip-card-back {
        transform: rotateY(0deg);
        -webkit-transform: rotateY(0deg);
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
    }

    .flip-card-back {
        transform: rotateY(180deg);
        -webkit-transform: rotateY(180deg);
    }
}